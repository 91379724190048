import { HeaderWidget } from '@widgets/header';
import { FooterWidget } from '@widgets/footer';
import { NotificationWidget } from '@widgets/notification';
import { CollegeSearchWidget } from '@widgets/search';
import StandaloneIdentityWidget, { PersonalizedIdentityWidget, HeroLoginWidget } from '@widgets/identity';
import { SiteSearchWidget } from '@widgets/sitesearch';
import { CollegeListWidget } from '@widgets/college-list';
export var BackwardCompatible = {
    CollegeListWidget: CollegeListWidget,
    HeaderWidget: HeaderWidget,
    FooterWidget: FooterWidget,
    NotificationWidget: NotificationWidget,
    CollegeSearchWidget: CollegeSearchWidget,
    HeroLoginWidget: HeroLoginWidget,
    SiteSearchWidget: SiteSearchWidget
};
export var Official = {
    GlobalHeader: HeaderWidget,
    GlobalFooter: FooterWidget,
    Notification: NotificationWidget,
    SiteSearch: SiteSearchWidget,
    Identity: StandaloneIdentityWidget,
    HeroIdentity: HeroLoginWidget,
    PersonalizedIdentity: PersonalizedIdentityWidget,
    CollegeSearch: CollegeSearchWidget
};
