exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cb-widget ._1yUNUJh1fTWG5G9pQCzdK5{padding:0;margin:0;padding:11px 31px;font-size:.875rem;line-height:1.7142857142857142em;font-family:\"Roboto Slab Bold\",sans-serif;border-radius:0;white-space:nowrap;border:1px solid transparent;font-weight:400;height:48px}.cb-widget ._1yUNUJh1fTWG5G9pQCzdK5._680LnykdO8yagARtD7YiV{color:#505050;border-color:#505050;background-color:#fff;text-decoration:underline}.cb-widget ._1yUNUJh1fTWG5G9pQCzdK5._3E4jFRFU50-pwXBy_17XQJ{border-color:#FEDB00;color:#505050;background-color:#FEDB00}.cb-widget ._1yUNUJh1fTWG5G9pQCzdK5:hover,.cb-widget ._1yUNUJh1fTWG5G9pQCzdK5:focus{text-decoration:underline}.cb-widget ._1yUNUJh1fTWG5G9pQCzdK5:focus{outline-style:solid;outline-color:#0077c8;outline-width:1px;outline-offset:-1px}.cb-widget ._1yUNUJh1fTWG5G9pQCzdK5:disabled{background:#ebe9e6;border-color:#ebe9e6;color:#989795}.cb-widget ._2KJTACv5QaNB2LgZ3PHAlh{width:32px;height:32px;padding:0;cursor:pointer;background:none;border:0;font-size:16px;line-height:1}\n", ""]);

// exports
exports.locals = {
	"college-list-button": "_1yUNUJh1fTWG5G9pQCzdK5",
	"college-list-link": "_680LnykdO8yagARtD7YiV",
	"college-list-primary": "_3E4jFRFU50-pwXBy_17XQJ",
	"college-list-close": "_2KJTACv5QaNB2LgZ3PHAlh"
};