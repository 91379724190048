var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { observer } from 'mobx-react';
import styles from './sitesearch.module.scss';
import $ from 'classnames';
import { DefaultSearch } from '@widgets/search';
import queryString from 'querystring';
var FilterView = function (p) {
    var columns = p.columns, additionalParams = p.additionalParams, isFiltersOn = p.isFiltersOn, resetURL = p.resetURL, onBackClick = p.onBackClick;
    return (React.createElement("div", { className: $(styles.filters) },
        React.createElement("div", { className: $(styles.content) },
            React.createElement("div", { className: $(styles.controls, "visible-xs visible-sm") },
                React.createElement("a", { onClick: onBackClick }, "< Back to Search"),
                isFiltersOn && React.createElement("a", { className: $(styles.clear), href: resetURL },
                    "Clear All ",
                    React.createElement("span", { className: "sr-only" }, "Filters"))),
            React.createElement("div", { className: $(styles.header) },
                React.createElement("h2", null, "Filters"),
                isFiltersOn && React.createElement("a", { className: $(styles.clear, "visible-md visible-lg"), href: resetURL },
                    "Clear All ",
                    React.createElement("span", { className: "sr-only" }, "Filters"))),
            columns.map(function (column, idx) { return (React.createElement(FilterFacetView, { additionalParams: additionalParams, key: idx, title: column.title, items: column.facets })); }))));
};
var FilterFacetView = function (p) {
    var title = p.title, items = p.items;
    return (React.createElement("div", { className: $(styles.facet) },
        React.createElement("h3", null,
            "By ",
            title),
        React.createElement("ul", null, items.map(function (item, idx) {
            return React.createElement(FacetView, { key: idx, isSelected: item.isSelected, query: item.query, title: item.title, total: item.total, undoquery: item.undoquery });
        }))));
};
var FacetView = function (p) {
    var _a;
    var isSelected = p.isSelected, query = p.query, title = p.title, total = p.total, undoquery = p.undoquery;
    return (React.createElement("li", { className: $((_a = {},
            _a["" + styles.active] = isSelected,
            _a)) },
        React.createElement("a", { href: query },
            title,
            " ",
            !isSelected && React.createElement(React.Fragment, null,
                "(",
                total,
                ")")),
        isSelected && React.createElement("a", { href: undoquery },
            React.createElement("span", { className: "sr-only" },
                "Remove ",
                title,
                " filter"),
            React.createElement("i", { className: "glyphicon-cb cb-icon-icn_close" }))));
};
var StatusView = function (p) {
    var pagelower = p.pagelower, pageupper = p.pageupper, total = p.total;
    return (React.createElement("div", { className: $(styles.status) },
        React.createElement("strong", null,
            "Displaying results ",
            pagelower,
            " - ",
            pageupper,
            " of ",
            total)));
};
var BannersView = function (p) {
    var banners = p.banners;
    return (React.createElement(React.Fragment, null, banners.map(function (banner, idx) { return (banner.content !== '' && React.createElement(BannerView, { key: idx, content: banner.content })); })));
};
var BannerView = function (p) {
    var content = p.content;
    return (React.createElement("div", { className: $(styles.banner) },
        React.createElement("div", { dangerouslySetInnerHTML: { __html: content } })));
};
var SearchResultView = function (p) {
    var name = p.name, description = p.description, url = p.url;
    return (React.createElement("div", { className: $(styles.result) },
        React.createElement("h3", null,
            React.createElement("a", { href: url }, name)),
        React.createElement("p", null, description),
        React.createElement("small", null, url)));
};
var SearchResultsView = function (p) {
    var results = p.results;
    return (React.createElement("div", { className: $(styles.results) }, results.map(function (result, idx) { return React.createElement(SearchResultView, { key: idx, name: result.title, description: result.description, url: result.url }); })));
};
var PaginationView = function (p) {
    var pagination = p.pagination;
    var last = pagination.last, previous = pagination.previous, next = pagination.next, pages = pagination.pages;
    return (React.createElement("div", { className: $(styles.pagination, "pagination") },
        React.createElement("nav", { "aria-label": "pagination" },
            React.createElement("ul", { className: "pagination" },
                previous !== '' && React.createElement("li", null,
                    React.createElement("a", { href: previous },
                        React.createElement("span", { className: "sr-only" }, "Go to page previous page"),
                        "Previous")),
                pages.map(function (page, idx) {
                    var _a;
                    return React.createElement("li", { key: idx, className: $((_a = {},
                            _a["active"] = page.selected === 'true' ? 'active' : false,
                            _a)) },
                        React.createElement("a", { href: page.link },
                            React.createElement("span", { className: "sr-only" }, "Go to page "),
                            page.page));
                }),
                next !== '' && React.createElement("li", null,
                    React.createElement("a", { href: next },
                        React.createElement("span", { className: "sr-only" }, "Go to page next page"),
                        "Next")),
                last !== '' && React.createElement("li", null,
                    React.createElement("a", { href: last },
                        React.createElement("span", { className: "sr-only" }, "Go to page last page"),
                        "Last"))))));
};
var FilterControlsView = function (p) {
    var activeFilters = p.activeFilters, resetURL = p.resetURL, onButtonClick = p.onButtonClick;
    return (React.createElement("div", { className: "visible-xs visible-sm hidden-md hidden-lg" },
        React.createElement("div", { className: $(styles.facet) },
            activeFilters.length > 0 &&
                React.createElement("ul", null, activeFilters.map(function (item, idx) {
                    return React.createElement(FacetView, { key: idx, isSelected: item.isSelected, query: item.query, title: item.title, total: item.total, undoquery: item.undoquery });
                })),
            React.createElement("div", { className: $(styles.controls) },
                React.createElement("button", { name: "add-filters", className: "btn btn-secondary", onClick: onButtonClick }, activeFilters.length ? 'Modify Filters' : 'Add Filters'),
                activeFilters.length > 0 && React.createElement("a", { href: resetURL }, "Clear All")))));
};
var TrackingLink = function (p) {
    var children = p.children, url = p.url, trackingCode = p.trackingCode;
    var attrs = {
        href: url,
        trackclickvalue: trackingCode
    };
    return React.createElement("a", __assign({}, attrs), children);
};
var SiteSearch = /** @class */ (function (_super) {
    __extends(SiteSearch, _super);
    function SiteSearch(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            word: null,
            data: {
                isDataAvailable: false,
                resetURL: null,
                pagination: null,
                results: null,
                columns: null,
                activeFilters: null,
                resultStats: null,
                banners: null
            },
            isRetrievingData: false,
            isFiltersOn: false,
            isFiltersActive: false,
            params: {}
        };
        var query = window.top.location.search.replace('?', '');
        var params = queryString.parse(query);
        delete params.callback;
        _this.state.params = params;
        if (_this.isValidSearch(params)) {
            _this.state.word = params['word'];
            _this.state.isRetrievingData = true;
        }
        return _this;
    }
    SiteSearch.prototype.isValidSearch = function (params) {
        return (params['word'] && params['tp'] && params['searchType']) ? true : false;
    };
    SiteSearch.prototype.onAddFiltersClick = function () {
        this.setState({
            isFiltersActive: !this.state.isFiltersActive
        });
    };
    SiteSearch.prototype.render = function () {
        var _a;
        var _b = this.props, destinationURL = _b.destinationURL, additionalParams = _b.additionalParams, tracker = _b.tracker;
        var _c = this.state, word = _c.word, data = _c.data, isRetrievingData = _c.isRetrievingData, isFiltersOn = _c.isFiltersOn, isFiltersActive = _c.isFiltersActive;
        var isDataAvailable = data.isDataAvailable, resetURL = data.resetURL, results = data.results, resultStats = data.resultStats, pagination = data.pagination, columns = data.columns, activeFilters = data.activeFilters, banners = data.banners;
        return (React.createElement("div", { id: tracker.id, "data-cbtrack-widget": "sitesearch", className: $('cb-widget', styles.sitesearch, (_a = {}, _a["" + styles.active] = isFiltersActive, _a)) },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-3" }, (!!columns && results.length !== 0) &&
                        React.createElement(FilterView, { columns: columns, resetURL: resetURL, additionalParams: additionalParams, isFiltersOn: isFiltersOn, onBackClick: this.onAddFiltersClick.bind(this) })),
                    React.createElement("div", { className: "col-md-9" },
                        React.createElement("div", { className: $(styles.search) },
                            React.createElement("h2", null, "Search Results"),
                            React.createElement("div", { className: $(styles.searchbar) },
                                React.createElement(DefaultSearch, { appId: 42, startValue: word, destinationURL: destinationURL, additionalParams: additionalParams })),
                            activeFilters &&
                                React.createElement(FilterControlsView, { activeFilters: activeFilters, resetURL: resetURL, onButtonClick: this.onAddFiltersClick.bind(this) })),
                        isRetrievingData &&
                            React.createElement("div", { role: "progressbar", "aria-valuetext": "Loading\u2026", className: $('cb-loader cb-loader-blue cb-loader-lg', styles.loader) }),
                        (!!results && results.length !== 0) ? (React.createElement("div", { className: $(styles.information) },
                            React.createElement(StatusView, { pagelower: resultStats.pagelower, pageupper: resultStats.pageupper, total: resultStats.total }),
                            React.createElement("hr", null),
                            React.createElement(BannersView, { banners: banners }),
                            React.createElement(SearchResultsView, { results: results }),
                            React.createElement("hr", null),
                            pagination.pages.length > 1 &&
                                React.createElement(PaginationView, { pagination: pagination, destinationURL: destinationURL, additionalParams: additionalParams })))
                            :
                                React.createElement(React.Fragment, null, (!isRetrievingData && isDataAvailable) &&
                                    React.createElement(React.Fragment, null,
                                        React.createElement("p", null,
                                            React.createElement("strong", null, "No matches for:"),
                                            word),
                                        React.createElement("hr", null),
                                        React.createElement("p", null, "No search results were found. Please make sure that all keywords are spelled correctly or try different or more general keywords. For your convenience, we've provided links to commonly requested information below."),
                                        React.createElement("div", { className: $(styles.group) },
                                            React.createElement("h2", null, "Free Tools"),
                                            React.createElement("ul", null,
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://bigfuture.collegeboard.org/college-search", trackingCode: "noresults-cs" }, "College Search")),
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://bigfuture.collegeboard.org/scholarship-search", trackingCode: "noresults-scholarship" }, "Scholarship Search")),
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://bigfuture.collegeboard.org/majors-careers", trackingCode: "noresults-majors-careers" }, "Majors and Careers Search")),
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://bigfuture.collegeboard.org/pay-for-college/tools-calculators", trackingCode: "finaid" }, "Financial Aid Calculators")))),
                                        React.createElement("div", { className: $(styles.group) },
                                            React.createElement("h2", null, "Information about the SAT"),
                                            React.createElement("ul", null,
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://collegereadiness.collegeboard.org/sat/register", trackingCode: "noresults-sat-reg" }, "SAT Registration")),
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://collegereadiness.collegeboard.org/sat/register/dates-deadlines", trackingCode: "noresults-sat-dates" }, "SAT Dates")),
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://collegereadiness.collegeboard.org/sat/scores", trackingCode: "noresults-sat-scores" }, "SAT Scores")),
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://collegereadiness.collegeboard.org/sat/practice", trackingCode: "noresults-sat-practice" }, "SAT Practice")))),
                                        React.createElement("div", { className: $(styles.group) },
                                            React.createElement("h2", null, "College Board Tests"),
                                            React.createElement("ul", null,
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://collegereadiness.collegeboard.org/sat", trackingCode: "noresults-sat" },
                                                        "SAT ",
                                                        React.createElement("sup", null, "\u00AE"))),
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://collegereadiness.collegeboard.org/psat-nmsqt-psat-10", trackingCode: "noresults-psat" },
                                                        "PSAT/NMSQT ",
                                                        React.createElement("sup", null, "\u00AE"))),
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://apstudent.collegeboard.org/home", trackingCode: "noresults-ap" },
                                                        "AP ",
                                                        React.createElement("sup", null, "\u00AE"))),
                                                React.createElement("li", null,
                                                    React.createElement(TrackingLink, { url: "https://clep.collegeboard.org/", trackingCode: "noresults-clep" },
                                                        "CLEP ",
                                                        React.createElement("sup", null, "\u00AE"))))))))))));
    };
    SiteSearch.prototype.componentDidMount = function () {
        var _this = this;
        this.props.tracker.mounted();
        var params = __assign(__assign({}, this.state.params), this.props.additionalParams);
        if (this.isValidSearch(params)) {
            this.props.store.retrieveSearch(params['word'], params, this.props.destinationURL).then(function (response) {
                _this.setState({
                    isRetrievingData: false,
                    isFiltersOn: response.activeFilters.length > 0 ? true : false,
                    data: __assign(__assign({}, response), { isDataAvailable: true })
                });
            });
        }
    };
    SiteSearch.prototype.componentWillUnmount = function () {
        this.props.tracker.unmounted();
    };
    SiteSearch = __decorate([
        observer
    ], SiteSearch);
    return SiteSearch;
}(React.Component));
export { SiteSearch };
