var escape = require("../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cb-widget ._2-KNwZzLSQUtDsCJ2Mrmkt{position:relative;height:24px;width:32px}.cb-widget ._2-KNwZzLSQUtDsCJ2Mrmkt .GQqX2NMo7xOAiWOEF_DCd{position:absolute;height:24px;width:32px;background:url(" + escape(require("../assets/bookmark.svg")) + ");background-size:32px 24px;background-repeat:no-repeat;background-position:top left;z-index:1}.cb-widget ._2-KNwZzLSQUtDsCJ2Mrmkt ._1ub1CpdBYI7_0mkhJetIRZ{width:18px;height:18px;border-radius:50%;background-color:#505050;overflow:hidden;position:absolute;right:-5px;top:-1px;z-index:2;animation:_3-JKRL_JjErrmI7GXV9Klz 0.5s}.cb-widget ._2-KNwZzLSQUtDsCJ2Mrmkt ._1ub1CpdBYI7_0mkhJetIRZ input{position:absolute;top:0;width:18px;height:18px;background:none;border:none;color:#fff;font-size:10px;line-height:17px;font-weight:700;font-family:\"RobotoSlab\", sans-serif;text-align:center;outline:none;transition-property:transform, opacity;text-transform:linear;transition-duration:0.25s}@keyframes _3-JKRL_JjErrmI7GXV9Klz{from{opacity:.2;width:2px;height:2px;right:8px;top:5px}to{opacity:1;width:18px;height:18px;right:-5px;top:-1px}}\n", ""]);

// exports
exports.locals = {
	"college-list-counter": "_2-KNwZzLSQUtDsCJ2Mrmkt",
	"college-list-count-icon": "GQqX2NMo7xOAiWOEF_DCd",
	"college-list-input-wrapper": "_1ub1CpdBYI7_0mkhJetIRZ",
	"appear": "_3-JKRL_JjErrmI7GXV9Klz"
};