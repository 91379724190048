import React, { Fragment } from 'react';
import { map } from 'lodash';
import styles from './styles.module.scss';
import $ from 'classnames';
export var dangerousHiddenInputs = function (inputs) {
    var html = '';
    map(inputs, function (value, name) {
        html += '<input key="' + name + '" type="hidden" name="' + name + '" value="' + value + '">';
    });
    return (React.createElement("div", { className: $(styles.hiddenInputContainer), dangerouslySetInnerHTML: { __html: html } }));
};
export var hiddenInputs = function (inputs) { return (React.createElement(Fragment, null, map(inputs, function (value, name) {
    return React.createElement("input", { key: name, type: "hidden", name: name, value: value });
}))); };
export var focus = function (element) {
    element.focus();
};
