var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { observable, computed } from 'mobx';
import { dpStore } from '@widgets/core';
import jsonp from 'jsonp-promise';
import queryString from 'querystring';
import { concat, each } from 'lodash';
var defaultSiteSearchConfig = {
    destinationURL: '',
    additionalParams: null
};
var SiteSearchStore = /** @class */ (function () {
    function SiteSearchStore() {
        this.props = null;
    }
    Object.defineProperty(SiteSearchStore.prototype, "config", {
        get: function () {
            return __assign(__assign(__assign({}, defaultSiteSearchConfig), this.props), (dpStore.dp.sitesearch));
        },
        enumerable: true,
        configurable: true
    });
    SiteSearchStore.prototype.retrieveSearch = function (query, additionalParams, destinationURL) {
        return __awaiter(this, void 0, void 0, function () {
            var formatURL, formatFacet, formatColumn, formatPagination, getActiveFilters, getResetURL, baseURL, baseParams, queryParams, searchURL, data, columns;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formatURL = function (query) {
                            if (destinationURL && query) {
                                var params = queryString.parse(query.replace('?', ''));
                                delete params.callback;
                                return destinationURL + '?' + queryString.stringify(params);
                            }
                            return query;
                        };
                        formatFacet = function (f) {
                            return {
                                total: f.count,
                                title: f.value,
                                query: formatURL(f.link),
                                undoquery: formatURL(f.undolink),
                                isSelected: f.selected === 'true' ? true : false
                            };
                        };
                        formatColumn = function (name, data) {
                            return {
                                title: name,
                                facets: data ? data.map(function (f) { return formatFacet(f); }) : []
                            };
                        };
                        formatPagination = function (pagination) {
                            return {
                                previous: formatURL(pagination.previous),
                                next: formatURL(pagination.next),
                                last: formatURL(pagination.last),
                                pages: pagination.pages.map(function (p) { return __assign(__assign({}, p), { query: formatURL(p.query) }); })
                            };
                        };
                        getActiveFilters = function (columns) {
                            var filters = [];
                            each(columns, function (c) { filters = concat(filters, c.facets); });
                            return filters.filter(function (f) { return f.isSelected === true; });
                        };
                        getResetURL = function () {
                            var params = queryString.parse(window.top.location.search.replace('?', ''));
                            for (var key in params) {
                                if (key.startsWith('q') && key.length <= 3) {
                                    delete params[key];
                                }
                            }
                            delete params.callback;
                            return formatURL(queryString.stringify(params));
                        };
                        baseURL = 'https://sp10044311.guided.ss-omtrdc.net/';
                        baseParams = {
                            tp: 'usearch',
                            x: 15,
                            x1: 't4',
                            y: 13,
                            siteType: 'default',
                            searchType: 'site',
                            word: query
                        };
                        queryParams = __assign(__assign({}, baseParams), additionalParams);
                        searchURL = baseURL + '?' + queryString.stringify(queryParams);
                        return [4 /*yield*/, jsonp(searchURL).promise];
                    case 1:
                        data = _a.sent();
                        columns = [
                            formatColumn('Audience', data.facets['t2'].values),
                            formatColumn('Type', data.facets['t1'].values),
                            formatColumn('Category', data.facets['t4'].values)
                        ];
                        return [2 /*return*/, {
                                resetURL: getResetURL(),
                                pagination: formatPagination(data.pagination),
                                results: data.results,
                                resultStats: data.resultcount,
                                columns: columns,
                                activeFilters: getActiveFilters(columns),
                                banners: [
                                    {
                                        id: 'related_search',
                                        title: 'Related Search',
                                        content: data.banners['related_search']
                                    },
                                    {
                                        id: 'site_bottom',
                                        title: 'Site Bottom',
                                        content: data.banners['site_bottom']
                                    },
                                    {
                                        id: 'site_bottom2',
                                        title: 'Site Bottom 2',
                                        content: data.banners['site_bottom2']
                                    },
                                    {
                                        id: 'site_top',
                                        title: 'Site Top',
                                        content: data.banners['site_top']
                                    },
                                    {
                                        id: 'trustee_search',
                                        title: 'Trustee Search',
                                        content: data.banners['trustee_search']
                                    },
                                    {
                                        id: 'global-noresults',
                                        title: 'Global No Results',
                                        content: data.banners['global-noresults']
                                    }
                                ]
                            }];
                }
            });
        });
    };
    __decorate([
        observable
    ], SiteSearchStore.prototype, "props", void 0);
    __decorate([
        computed
    ], SiteSearchStore.prototype, "config", null);
    return SiteSearchStore;
}());
export default SiteSearchStore;
