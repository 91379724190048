var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import $ from 'classnames';
import CarouselSlot from './CarouselSlot';
import './Carousel.scss';
var default_1 = /** @class */ (function (_super) {
    __extends(default_1, _super);
    function default_1() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            position: 0,
            direction: 'next',
            sliding: false
        };
        _this.prevSlide = function () {
            var position = _this.state.position;
            var children = _this.props.children;
            var numItems = children.length;
            _this.doSliding('prev', position === 0 ? numItems - 1 : position - 1);
        };
        _this.doSliding = function (direction, position) {
            _this.setState({
                sliding: true,
                direction: direction,
                position: position
            });
            setTimeout(function () {
                _this.setState({
                    sliding: false
                });
            }, 50);
        };
        return _this;
    }
    default_1.prototype.primaryAction = function (e) {
        var action = e.data.action;
        console.log('---- Requested action on Carousel: ', action);
        switch (action) {
            case 'stayConnected':
                this.slideNum(1);
                this.props.callbackprimary(e);
                break;
            case 'optOut':
                this.slideNum(3);
                this.props.callbackprimary(e);
                break;
            case 'interested':
                this.nextSlide();
                this.props.callbackprimary(e);
                break;
            case 'join':
                this.nextSlide();
                this.props.callbackprimary(e);
                break;
            default:
            // code block
        }
    };
    default_1.prototype.linkAction = function (e) {
        var action = e.data.action;
        if (parseInt(action, 10)) {
            this.slideNum(parseInt(action, 10));
        }
        console.log('---- Requested action on Carousel: ', action);
        switch (action) {
            case 'stayConnected':
                this.slideNum(1);
                break;
            default:
        }
        this.props.callbacklink(e);
    };
    default_1.prototype.getOrder = function (itemIndex) {
        var position = this.state.position;
        var children = this.props.children;
        var numItems = children.length || 1;
        if (itemIndex - position < 0) {
            return numItems - Math.abs(itemIndex - position);
        }
        return itemIndex - position;
    };
    default_1.prototype.slideNum = function (num) {
        var position = this.state.position;
        var direction = (position > num) ? 'prev' : 'next';
        this.doSliding(direction, num);
    };
    default_1.prototype.nextSlide = function () {
        var position = this.state.position;
        var children = this.props.children;
        var numItems = children.length || 1;
        this.doSliding('next', (position === numItems - 1) ? 0 : (position + 1));
    };
    default_1.prototype.render = function () {
        var _this = this;
        var children = this.props.children;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "carousel-wrapper" },
                React.createElement("div", { className: $('carousel-container', {
                        'sliding': this.state.sliding
                    }, {
                        'prev': (this.state.direction === 'prev' && this.state.sliding)
                    }) }, children.map(function (child, index) { return (React.createElement(CarouselSlot, { key: 'carouselSlot' + index, index: index, children: child, order: _this.getOrder(index), callbackprimary: _this.primaryAction.bind(_this), callbacklink: _this.linkAction.bind(_this) })); })))));
    };
    return default_1;
}(React.Component));
export default default_1;
