exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cb-widget ._2cdtQTDspFaZNJMzptGRPU{border-bottom:1px solid black}.cb-widget ._29n98JvbKDkhfSqoEJPp--{transition:max-height 1s cubic-bezier(0, 1, 0, 1) !important;overflow-y:hidden !important;max-height:500px !important}.cb-widget ._29n98JvbKDkhfSqoEJPp--._2N6L0CCBckgJEm3vLlhhhn{max-height:0px !important}.cb-widget ._29n98JvbKDkhfSqoEJPp-- p.notification-message{margin-bottom:0;font-size:14px !important;color:#505050 !important}.cb-widget ._29n98JvbKDkhfSqoEJPp-- p.notification-message p{padding-left:0 !important}.cb-widget ._2VYgB3a3gx3f7B2AdoX7PM{border-bottom:0 !important;border-left:0 !important;border-right:0 !important;margin-bottom:0 !important}\n", ""]);

// exports
exports.locals = {
	"widgetRoot": "_2cdtQTDspFaZNJMzptGRPU",
	"root": "_29n98JvbKDkhfSqoEJPp--",
	"closing": "_2N6L0CCBckgJEm3vLlhhhn",
	"alert": "_2VYgB3a3gx3f7B2AdoX7PM"
};