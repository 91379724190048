var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import $ from 'classnames';
import './Bell.scss';
var RingingBell = require('../assets/bell_ring.gif');
var Blank = require('../assets/blank.png');
var default_1 = /** @class */ (function (_super) {
    __extends(default_1, _super);
    function default_1() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            ring: false,
            src: Blank
        };
        return _this;
    }
    default_1.prototype.componentDidUpdate = function (prevProps) {
        if (prevProps.ring !== this.props.ring) {
            if (this.props.ring === false) {
                this.setState({ src: Blank });
                this.setState({ ring: false });
            }
            else {
                this.setState({ src: RingingBell });
                this.setState({ ring: true });
            }
        }
    };
    default_1.prototype.render = function () {
        return (React.createElement("div", { className: $('college-list-bell-icon', {
                'ring': this.state.ring
            }) },
            React.createElement("img", { className: "college-list-bell-ring", "aria-hidden": "true", src: this.state.src })));
    };
    return default_1;
}(React.Component));
export default default_1;
