var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import $ from 'classnames';
import './Buttons.scss';
var Button = /** @class */ (function (_super) {
    __extends(Button, _super);
    function Button() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.btnRef = React.createRef();
        return _this;
    }
    Button.prototype.btnFocus = function () {
        if (this.btnRef.current) {
            this.btnRef.current.focus();
        }
    };
    Button.prototype.render = function () {
        var _a = this.props, label = _a.label, ariaLabel = _a.ariaLabel, ariaDesc = _a.ariaDesc, isClose = _a.isClose, disabled = _a.disabled, callback = _a.callback;
        var buttonType = 'college-list-' + this.props.buttonType;
        return (React.createElement(React.Fragment, null, !isClose ? (React.createElement("button", { className: $('college-list-button', buttonType), "aria-label": ariaLabel, "aria-describedby": ariaDesc, type: "button", disabled: disabled, onClick: callback, ref: this.btnRef }, label)) : (React.createElement("button", { type: "button", className: "college-list-close", "aria-label": label, onClick: callback, ref: this.btnRef },
            React.createElement("span", { "aria-hidden": "true" }, "\u00D7")))));
    };
    return Button;
}(React.Component));
export default Button;
