var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import './Counter.scss';
var default_1 = /** @class */ (function (_super) {
    __extends(default_1, _super);
    function default_1() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.inputRef = React.createRef();
        _this.state = {
            curValue: _this.props.count
        };
        _this.changeValue = function (newValue) {
            _this.setState({ curValue: newValue !== 100 ? newValue : 99 });
        };
        _this.handleValueChange = function (newValue) {
            if (!newValue) {
                newValue = 1;
            }
            if (newValue < 0) {
                newValue = 1;
            }
            // slide up or down
            _this.inputRef.current.style.transform = newValue >= _this.state.curValue ? 'translateY(-100%)' : 'translateY(100%)';
            _this.inputRef.current.style.opacity = '0';
            setTimeout(function () {
                _this.inputRef.current.style.transitionDuration = '0s';
                _this.inputRef.current.style.transform = newValue >= _this.state.curValue ? 'translateY(100%)' : 'translateY(-100%)';
                _this.inputRef.current.style.opacity = '0';
                _this.changeValue(newValue);
                setTimeout(function () {
                    _this.inputRef.current.style.transitionDuration = '0.3s';
                    _this.inputRef.current.style.transform = 'translateY(0)';
                    _this.inputRef.current.style.opacity = '1';
                }, 20);
                _this.props.nextStep('mitra');
            }, 250);
        };
        return _this;
    }
    default_1.prototype.componentDidUpdate = function (prevProps) {
        if (prevProps.count !== this.props.count) {
            if (this.inputRef.current) {
                this.handleValueChange(this.props.count);
            }
        }
    };
    default_1.prototype.render = function () {
        var count = this.props.count;
        return (React.createElement("div", { className: "college-list-counter" },
            React.createElement("div", { className: "college-list-count-icon" }),
            (count > 0) ?
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "college-list-input-wrapper" },
                        React.createElement("input", { ref: this.inputRef, maxLength: 2, type: "text", value: this.state.curValue, disabled: true })))
                : null));
    };
    return default_1;
}(React.Component));
export default default_1;
