var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import $ from 'classnames';
import Button from '../Button';
import Single from './Single';
import Carousel from '../Carousel';
import './Popover.scss';
var default_1 = /** @class */ (function (_super) {
    __extends(default_1, _super);
    function default_1() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.refPopover = React.createRef();
        _this.refCloseBtn = React.createRef();
        _this.state = {
            show: _this.props.show,
            closeEffect: false
        };
        // --------Manage Slides
        _this.handelCallback = function (e) {
            var action = e.data.action;
            console.log('---- Requested action on Popover: ', action);
            switch (action) {
                case 'signIn':
                    console.log('Trigger Login modal event on document');
                    var event = new CustomEvent('login_modal');
                    document.dispatchEvent(event);
                    break;
                case 'signUp':
                    console.log('Redirect to Sign Up');
                    break;
                case 'update':
                    console.log('Redirect to Update Account');
                    break;
                case 'close':
                    console.log('Close Alert');
                    _this.setState({
                        show: false
                    });
                    break;
                case 'done':
                    console.log('Done Alert');
                    _this.props.callback(e);
                    break;
                case 'stayConnected':
                    console.log('Stay Connected API');
                    break;
                case 'interested':
                    console.log('Interested API');
                    break;
                case 'join':
                    console.log('Join API');
                    break;
                default:
            }
        };
        return _this;
    }
    default_1.prototype.componentDidMount = function () {
        // a11y
        if (this.props.show) {
            window.addEventListener('keydown', this.listenKeyboard.bind(this), true);
        }
    };
    default_1.prototype.componentWillUnmount = function () {
        // a11y
        if (this.props.show) {
            window.removeEventListener('keydown', this.listenKeyboard.bind(this), true);
        }
    };
    // a11y
    default_1.prototype.componentDidUpdate = function (prevProps) {
        if (prevProps.show !== this.props.show) {
            this.setState({
                show: this.props.show
            });
        }
        if (prevProps.close !== this.props.close) {
            this.setState({
                closeEffect: this.props.close
            });
        }
    };
    // a11y
    default_1.prototype.listenKeyboard = function (e) {
        if (e.key === 'Escape' || e.keyCode === 27) {
            // this.props.callback(e);
            e.preventDefault();
            if (this.props.active) {
                this.setState({
                    show: !this.state.show
                });
            }
        }
    };
    // a11y
    default_1.prototype.focusOnClose = function () {
        setTimeout(function () {
            if (this.refCloseBtn.current) {
                this.refCloseBtn.current.btnFocus();
            }
        }.bind(this), 0);
    };
    default_1.prototype.render = function () {
        var _this = this;
        var _a = this.props, id = _a.id, triggerCallback = _a.triggerCallback, data = _a.data;
        var showPopover = function (e) {
            e.preventDefault();
            triggerCallback(e);
        };
        var handleBackgroundClick = function (e) {
            e.preventDefault();
            if (e.target === e.currentTarget) {
                showPopover(e);
            }
        };
        var assignSlideData = function (num) {
            return React.createElement(Single, { imgLabel: data[num].imgLabel, imgSrc: data[num].imgSrc, lineBreak: data[num].lineBreak, title: data[num].title, message: data[num].message, primaryLabel: data[num].primaryLabel, primaryAction: data[num].primaryAction, callbackprimary: _this.handelCallback, linkLabel: data[num].linkLabel, linkAction: data[num].linkAction, callbacklink: _this.handelCallback, disclaimer: data[num].disclaimer });
        };
        var alertBlock = function () {
            var Slide1 = null;
            var Slide2 = null;
            var Slide3 = null;
            var Slide4 = null;
            // Carousel
            if (data.length > 1) {
                Slide1 = (data[0]) ? assignSlideData(0) : null;
                Slide2 = (data[1]) ? assignSlideData(1) : null;
                Slide3 = (data[2]) ? assignSlideData(2) : null;
                Slide4 = (data[3]) ? assignSlideData(3) : null;
                return (React.createElement(Carousel, { callbacklink: _this.handelCallback, callbackprimary: _this.handelCallback },
                    React.createElement("p", null),
                    Slide1,
                    Slide2,
                    Slide3,
                    Slide4));
            }
            else {
                return (React.createElement(React.Fragment, null, _this.props.data.map(function (a, index) { return (React.createElement(Single, { key: index, imgLabel: a.imgLabel, imgSrc: a.imgSrc, lineBreak: a.lineBreak, title: a.title, message: a.message, primaryLabel: a.primaryLabel, primaryAction: a.primaryAction, callbackprimary: _this.handelCallback, linkLabel: a.linkLabel, linkAction: a.linkAction, callbacklink: _this.handelCallback, disclaimer: a.disclaimer })); })));
            }
        };
        return (React.createElement("div", { className: "college-list-popover" }, this.state.show &&
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: $('college-list-popover-container', 'popover-top', { 'popover-close': this.state.closeEffect }), ref: this.refPopover, role: "popover", "aria-label": "College List Alert", "aria-hidden": !this.state.show, tabIndex: (this.state.show) ? 0 : -1 },
                    React.createElement("div", { className: "college-list-popover-header" },
                        React.createElement("p", null, "Notification"),
                        React.createElement(Button, { label: "Close Popover", callback: showPopover, ref: this.refCloseBtn, isClose: true })),
                    React.createElement("div", { className: "college-list-popover-message", id: id, role: "Popover" }, alertBlock()),
                    React.createElement("div", { className: "college-list-popover-footer" })),
                React.createElement("div", { className: $('college-list-popover-backdrop ', { 'college-list-show': this.state.show }, { 'popover-close': this.state.closeEffect }), onClick: handleBackgroundClick }))));
    };
    return default_1;
}(React.Component));
export default default_1;
