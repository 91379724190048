var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import $ from 'classnames';
import Bell from '../Bell';
import Counter from '../Counter';
import Popover from '../Popover';
import defaultAlerts from '../Services/defaultAlerts';
import './CollegeList.scss';
var default_1 = /** @class */ (function (_super) {
    __extends(default_1, _super);
    function default_1() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.rootRef = React.createRef();
        _this.state = {
            authCount: 0,
            authAlert: false,
            ringBell: false,
            showAlert: false,
            activeAlert: false,
            closeEffect: false,
            data: null
        };
        _this.nextStep = function () {
            if (_this.props.count < 1) {
                return;
            }
            // TBD
            // Do something for Non-Auth, Count: 4
            // if (( !levity.isLoggedIn  && this.props.count === 1 )|| (levity.isLoggedIn  && this.state.authAlert)) {
            if ((!_this.props.auth && _this.props.count === 1) || (_this.props.auth && _this.state.authAlert)) {
                setTimeout(function () {
                    _this.setState({
                        ringBell: true,
                        activeAlert: true
                    });
                    setTimeout(function () {
                        _this.setState({
                            showAlert: true
                        });
                    }, 1900);
                }, 800);
            }
            // else {
            // this.setState({
            //   ringBell: false,
            //   showAlert: false,
            //   activeAlert: true
            // })
            // }
        };
        _this.resetBell = function () {
            _this.setState({
                ringBell: false,
                showAlert: false,
                activeAlert: false
            });
        };
        _this.myColleges = function (e) {
            e.preventDefault();
            console.log('Redirect to ');
        };
        _this.retrieveScreen = function () {
            console.log('------- Filter');
            console.log('auth: ' + _this.props.auth + ' update: ' + _this.props.update + ' reOpt: ' + _this.props.reOpt + ' opt: ' + _this.props.opt);
            var alerts = defaultAlerts.filter(function (obj) {
                return (obj.auth === _this.props.auth &&
                    obj.update === _this.props.update &&
                    obj.reOpt === _this.props.reOpt &&
                    obj.opt === _this.props.opt);
            });
            if (alerts[0]) {
                var alert = alerts[0].alerts;
                if (alert) {
                    console.log(alert);
                    return alert;
                }
            }
            return null;
        };
        _this.triggerPopover = function (e) {
            e.preventDefault();
            if (_this.state.activeAlert) {
                if (_this.state.showAlert) {
                    _this.setState({
                        closeEffect: true
                    });
                    setTimeout(function () {
                        this.setState({
                            closeEffect: false,
                            showAlert: false
                        });
                    }.bind(_this), 500);
                }
                else {
                    _this.setState({
                        showAlert: !_this.state.showAlert
                    });
                }
            }
        };
        return _this;
    }
    default_1.prototype.componentDidMount = function () {
        // TBD Only update when widget Mount
        this.setState({
            data: this.retrieveScreen()
        });
        // if (levity.isLoggedIn) {
        if (this.props.auth) {
            this.setState({
                authCount: this.props.count
            });
            //   API.getOptStatus(levity.authSession.sessionId).then((d)=> {
            //     if (!d) {
            //       const obj = {
            //         matchIndicator: 'N',
            //         studentSearchOptInInd: 'N'
            //       }
            //       console.log(obj)
            //     } else {
            //       console.log(d)
            //     }
            //   });
        }
    };
    default_1.prototype.componentDidUpdate = function (prevProps) {
        // TBD: Auth, first time a slide is selected => Ring
        // Get new data is student status changes
        if (prevProps.auth !== this.props.auth ||
            prevProps.update !== this.props.update ||
            prevProps.reOpt !== this.props.reOpt ||
            prevProps.opt !== this.props.opt) {
            this.setState({
                data: this.retrieveScreen()
            });
            if (this.props.auth) {
                this.setState({
                    authCount: this.props.count
                });
            }
            this.resetBell();
        }
        if (prevProps.count !== this.props.count) {
            // if (levity.isLoggedIn) {
            if (this.props.auth) {
                if ((this.props.count - this.state.authCount) == 1) {
                    this.setState({
                        authAlert: true
                    });
                }
            }
            if (this.props.count < 1) {
                this.resetBell();
            }
        }
    };
    default_1.prototype.render = function () {
        var _a = this.props, count = _a.count, active = _a.active;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: $('college-list', { 'active': active }), ref: this.rootRef },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-xs-12" },
                            React.createElement("div", { className: "college-list-wrapper" },
                                React.createElement("div", { className: "college-list-title" },
                                    React.createElement("a", { href: "#", onClick: this.myColleges }, "My Colleges"),
                                    React.createElement(Counter, { count: count, nextStep: this.nextStep })),
                                React.createElement("div", { className: "college-list-notice" },
                                    React.createElement("div", { className: "college-list-stroke" }),
                                    React.createElement("a", { href: "#", className: $('college-list-popover-trigger', {
                                            'active': this.state.activeAlert
                                        }), "aria-describedby": "collegeListPopover", "aria-label": "College List - Popover", onClick: this.triggerPopover },
                                        React.createElement(Bell, { ring: this.state.ringBell })))))))),
            React.createElement("div", { className: $('college-list-popover-placeholder', { 'active': this.state.activeAlert }) },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-xs-12" },
                            React.createElement("div", { className: "college-list-wrapper" },
                                React.createElement(Popover, { ariaLabel: 'College List - Popover', id: 'collegeListPopover', active: this.state.activeAlert, show: this.state.showAlert, close: this.state.closeEffect, callback: this.resetBell, triggerCallback: this.triggerPopover, data: this.state.data }))))))));
    };
    return default_1;
}(React.Component));
export default default_1;
