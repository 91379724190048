var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import $ from 'classnames';
import { observer } from 'mobx-react';
import SuggestionsList from './SuggestionList';
import { hiddenInputs, dangerousHiddenInputs } from './utils';
import SearchWidgetContainer from './SearchWidgetContainer';
import SearchInput from './SearchInput';
import SearchIcon from './SearchIcon';
import { URL } from '@widgets/core';
import { merge } from 'lodash';
var defaultSearchURL = 'https://www.collegeboard.org/search';
var SearchWidgetCore = /** @class */ (function (_super) {
    __extends(SearchWidgetCore, _super);
    function SearchWidgetCore() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.formRef = React.createRef();
        _this.searchInputRef = React.createRef();
        _this.suggestionsListRef = React.createRef();
        return _this;
    }
    Object.defineProperty(SearchWidgetCore.prototype, "input", {
        get: function () {
            return this.searchInputRef.current.input;
        },
        enumerable: true,
        configurable: true
    });
    SearchWidgetCore.prototype.render = function () {
        var _this = this;
        var theme = this.props.theme;
        var submitType = theme.submitType;
        var _a = this.props, sensitivity = _a.sensitivity, destinationURL = _a.destinationURL, startValue = _a.startValue, limit = _a.limit, siteType = _a.siteType, searchType = _a.searchType, usePredefinedSearchType = _a.usePredefinedSearchType, clearSuggestions = _a.clearSuggestions, suggestions = _a.suggestions, fetchSuggestions = _a.fetchSuggestions, additionalParams = _a.additionalParams, tracker = _a.tracker;
        var _b = this.props, facetList = _b.facetList, facetValues = _b.facetValues;
        var defaultHiddenValues = {
            tp: 'usearch',
            x: '15',
            x1: 't4',
            y: '13',
            searchType: searchType,
            siteType: siteType
        };
        var facetQueryParameters = {};
        var columnValues = ['u2', 'u1', 'u4'];
        if (usePredefinedSearchType) {
            facetList = ['Audience', 'Type', 'Category'];
            facetValues = {};
            var predefinedSiteTypes = {
                // searchType for searching on a specific website that will return results with a specific filter on
                'accu_site': { 'Category': "ACCUPLACER" },
                'clep_site': { 'Category': 'CLEP' },
                'ap_site': { 'Category': 'AP' },
                'aps_site': { 'Category': 'AP' },
                'apc_site': { 'Category': 'AP' },
                'satsite': { 'Category': 'SAT' },
                'sb_site': { 'Category': 'SpringBoard' },
                'bf_site': { 'Category': 'College Planning' }
            };
            facetValues = predefinedSiteTypes[searchType];
        }
        if (facetValues) {
            var count_1 = 1;
            facetList.map(function (facetName, idx) {
                var facet = facetValues[facetName];
                if (facet) {
                    var xName = 'x' + count_1;
                    var qName = 'q' + count_1;
                    var facetObject = {};
                    var searchObject = {};
                    searchObject[xName] = columnValues[idx];
                    facetObject[qName] = facet;
                    facetQueryParameters = merge(facetQueryParameters, facetObject);
                    defaultHiddenValues = merge(defaultHiddenValues, searchObject);
                    count_1++;
                }
            });
        }
        var hiddenValues = __assign(__assign({}, defaultHiddenValues), additionalParams);
        var suggestionsListId = '' + Date.now();
        var submitForm = function () { return _this.input.value && _this.formRef.current.submit(); };
        var preventSubmitIfEmpty = function (e) { if (!_this.input.value)
            e.preventDefault(); };
        var searchURL = new URL((destinationURL ? destinationURL : defaultSearchURL));
        return (React.createElement(SearchWidgetContainer, { id: tracker.id, groupName: "search", className: theme.container, onBlur: function () {
                clearSuggestions();
                _this.searchInputRef.current.ref.current.setAttribute('aria-activedescendant', '');
            } },
            React.createElement("form", { className: $(theme.form), action: searchURL.href, method: "get", ref: this.formRef, onSubmit: preventSubmitIfEmpty },
                hiddenInputs(hiddenValues),
                dangerousHiddenInputs(facetQueryParameters),
                React.createElement(SearchInput, { ref: this.searchInputRef, className: theme.input, startValue: startValue, sensitivity: sensitivity, suggestions: suggestions, fetchSuggestions: fetchSuggestions, suggestionsList: this.suggestionsListRef, suggestionsListId: suggestionsListId }),
                submitType === 'button'
                    ? React.createElement("button", { className: $(theme.button, 'btn btn-sm btn-primary') }, "Search")
                    : React.createElement(SearchIcon, { className: theme.link, "data-cbtrack-link": "nav", onClick: submitForm })),
            React.createElement(SuggestionsList, { id: suggestionsListId, ref: this.suggestionsListRef, theme: theme.suggestions || {}, limit: limit, suggestions: suggestions, onFocus: function (id) {
                    _this.input.setAttribute('aria-activedescendant', id);
                }, onSelection: function (suggestion) {
                    _this.input.value = suggestion.value.replace(/-/gi, ' ');
                    submitForm();
                }, onEscape: function () {
                    _this.input.focus();
                    clearSuggestions();
                } })));
    };
    SearchWidgetCore.prototype.componentDidMount = function () {
        this.props.tracker.mounted();
    };
    SearchWidgetCore.prototype.componentWillUnmount = function () {
        this.props.tracker.unmounted();
    };
    SearchWidgetCore.defaultProps = {
        theme: {}
    };
    SearchWidgetCore = __decorate([
        observer
    ], SearchWidgetCore);
    return SearchWidgetCore;
}(React.Component));
export default SearchWidgetCore;
