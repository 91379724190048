var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import Button from '../Button';
import './Popover.scss';
var default_1 = /** @class */ (function (_super) {
    __extends(default_1, _super);
    function default_1() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handelCallbackPrimary = function (e) {
            console.log('CLICK in Single, callbackprimary');
            e.data = { action: _this.props.primaryAction };
            _this.props.callbackprimary(e);
        };
        _this.handelCallbackLink = function (e) {
            console.log('CLICK in Single, callbacklink');
            e.data = { action: _this.props.linkAction };
            _this.props.callbacklink(e);
        };
        return _this;
    }
    default_1.prototype.render = function () {
        var _a = this.props, imgLabel = _a.imgLabel, imgSrc = _a.imgSrc, lineBreak = _a.lineBreak, title = _a.title, message = _a.message, primaryLabel = _a.primaryLabel, linkLabel = _a.linkLabel, disclaimer = _a.disclaimer;
        var img = require('../assets/' + imgSrc);
        return (React.createElement("div", { className: "college-list-single-layout" },
            React.createElement("img", { alt: imgLabel, src: img }),
            (lineBreak) ?
                React.createElement(React.Fragment, null,
                    React.createElement("p", null,
                        React.createElement("strong", null, title)),
                    React.createElement("div", { className: "college-list-message", dangerouslySetInnerHTML: { __html: message } }))
                :
                    React.createElement("p", null,
                        React.createElement("strong", null, title),
                        " ",
                        message),
            (primaryLabel) ?
                React.createElement(Button, { label: primaryLabel, callback: this.handelCallbackPrimary, buttonType: "primary", isClose: false })
                : null,
            (linkLabel) ?
                React.createElement(Button, { label: linkLabel, callback: this.handelCallbackLink, buttonType: "link", isClose: false })
                : null,
            (disclaimer) ?
                React.createElement("div", { className: "college-list-disclaimer", dangerouslySetInnerHTML: { __html: disclaimer } })
                : null));
    };
    return default_1;
}(React.Component));
export default default_1;
