var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
var default_1 = /** @class */ (function (_super) {
    __extends(default_1, _super);
    function default_1() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    default_1.prototype.render = function () {
        var _a = this.props, children = _a.children, index = _a.index, order = _a.order, callbackprimary = _a.callbackprimary, callbacklink = _a.callbacklink;
        var flexOrder = {
            order: order
        };
        if (children) {
            var child = React.Children.map(children, function (c) {
                if (typeof c.type === 'string') {
                    if (c.type === 'p')
                        return null;
                }
                return React.cloneElement(c, {
                    callbackprimary: function (e) { return callbackprimary(e); },
                    callbacklink: function (e) { return callbacklink(e); }
                });
            });
            return (React.createElement("div", { className: "carousel-slot", key: index, style: flexOrder }, child));
        }
        else {
            return null;
        }
    };
    return default_1;
}(React.Component));
export default default_1;
