export default [
    {
        "auth": false,
        "update": false,
        "reOpt": false,
        "opt": false,
        "alerts": [
            {
                "type": "single",
                "imgLabel": "lorem ipsum",
                "imgSrc": "img1.png",
                "lineBreak": false,
                "title": "Great Work!",
                "message": "You're building a college list. Don't lose track of it.",
                "primaryLabel": "Sign In",
                "primaryAction": "signIn",
                "linkLabel": "Sign Up",
                "linkAction": "signUp"
            }
        ]
    },
    {
        "auth": true,
        "update": false,
        "reOpt": false,
        "opt": false,
        "alerts": [
            {
                "type": "single",
                "imgLabel": "lorem ipsum",
                "imgSrc": "img1.png",
                "lineBreak": true,
                "title": "Authenticated, Temp",
                "message": "You're building a college list. Don't lose track of it."
            }
        ]
    },
    {
        "auth": true,
        "update": true,
        "reOpt": false,
        "opt": false,
        "alerts": [
            {
                "type": "single",
                "imgLabel": "lorem ipsum",
                "imgSrc": "img1.png",
                "lineBreak": true,
                "title": "Update Your Account",
                "message": "We can better serve you on the path to college when your account is up to date",
                "primaryLabel": "Update Now",
                "primaryAction": "update",
                "linkLabel": "Maybe later",
                "linkAction": "close"
            }
        ]
    },
    {
        "auth": true,
        "update": false,
        "reOpt": true,
        "opt": false,
        "alerts": [
            {
                "slide": 0,
                "type": "carousel",
                "imgLabel": "lorem ipsum",
                "imgSrc": "img1.png",
                "lineBreak": true,
                "title": "Keep Connecting with Colleges",
                "message": "You're building a college list. Don't lose track of it.",
                "primaryLabel": "Yes, Stay Connected",
                "primaryAction": "stayConnected",
                "linkLabel": "Opt Out",
                "linkAction": "2"
            },
            {
                "slide": 1,
                "type": "carousel",
                "imgLabel": "lorem ipsum",
                "imgSrc": "img1.png",
                "lineBreak": true,
                "title": "Thanks!",
                "message": "You're still a part of Student Search Service and will keep hearing from colleges.",
                "linkLabel": "Done",
                "linkAction": "done"
            },
            {
                "slide": 2,
                "type": "carousel",
                "imgLabel": "lorem ipsum",
                "imgSrc": "img1.png",
                "lineBreak": true,
                "title": "Are you sure you want to opt out?",
                "message": "You're still a part of Student Search Service and will keep hearing from colleges.",
                "primaryLabel": "Yes, opt me out",
                "primaryAction": "optOut",
                "linkLabel": "I'd like to stay connected",
                "linkAction": "stayConnected"
            },
            {
                "slide": 3,
                "type": "carousel",
                "imgLabel": "lorem ipsum",
                "imgSrc": "img1.png",
                "lineBreak": true,
                "title": "You're Opted Out.",
                "message": "If you change your mind, you can still <a href='#'>opt in</a> to student Search Service",
                "linkLabel": "Done",
                "linkAction": "done"
            }
        ]
    },
    {
        "auth": true,
        "update": false,
        "reOpt": false,
        "opt": true,
        "alerts": [
            {
                "slide": 0,
                "type": "carousel",
                "imgLabel": "lorem ipsum",
                "imgSrc": "img1.png",
                "lineBreak": true,
                "title": "Help Colleges Find You",
                "message": "Right now, colleges like <strong>AMDA College and Conservatory of the Performing Arts</strong> could be interested in you. Want them to know you're interested",
                "primaryLabel": "Yes, Tell Colleges About Me",
                "primaryAction": "interested",
                "linkLabel": "Maybe later",
                "linkAction": "close"
            },
            {
                "slide": 1,
                "type": "carousel",
                "imgLabel": "lorem ipsum",
                "imgSrc": "img1.png",
                "lineBreak": true,
                "title": "Join Student Search Service",
                "message": "When you join, we'll share your information and list with colleges interested in students like you.",
                "primaryLabel": "Join Now",
                "primaryAction": "join",
                "disclaimer": "By opting in to Students Search Service, you agree to our <a href=\"#\">Privacy Policy</a> and <a href=\"#\">Terms & Condition</a>."
            },
            {
                "slide": 2,
                "type": "carousel",
                "imgLabel": "lorem ipsum",
                "imgSrc": "img1.png",
                "lineBreak": true,
                "title": "Thank you!",
                "message": "You're on your way to finding the right college for you.",
                "linkLabel": "Done",
                "linkAction": "close"
            }
        ]
    }
];
