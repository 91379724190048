import React from 'react';
import { widget } from '@widgets/core';
import SearchWidgetCore from './SearchWidgetCore';
import CollegeSearchWidgetCore from './CollegeSearch/';
import { collegeSearchStore, generateSearchStore } from './store';
import globalHeaderStyles from './GlobalHeaderSearch/styles.module.scss';
import defaultStyles from './DefaultSearch/styles.module.scss';
export var DefaultSearch = widget('search')(function () { return generateSearchStore(); }, function (store, tracker) { return (React.createElement(SearchWidgetCore, { tracker: tracker, limit: store.config.limit, destinationURL: store.config.destinationURL, searchType: store.config.searchType, sensitivity: store.config.sensitivity, suggestions: store.suggestions, facetList: store.config.facetList, startValue: store.config.startValue, fetchSuggestions: store.fetchSuggestions, clearSuggestions: store.clear, additionalParams: store.config.additionalParams, theme: {
        submitType: 'button',
        button: defaultStyles.button,
        form: defaultStyles.form,
        input: defaultStyles.input
    } })); });
export var GlobalHeaderSearch = widget('search')(function () { return generateSearchStore(); }, function (store, tracker) { return (React.createElement(SearchWidgetCore, { tracker: tracker, icon: true, limit: store.config.limit, siteType: store.config.siteType, searchType: store.config.searchType, usePredefinedSearchType: store.config.usePredefinedSearchType, sensitivity: store.config.sensitivity, startValue: "", suggestions: store.suggestions, facetList: store.config.facetList, facetValues: store.config.facetValues, fetchSuggestions: store.fetchSuggestions, clearSuggestions: store.clear, additionalParams: store.config.additionalParams, theme: {
        form: globalHeaderStyles.form,
        input: globalHeaderStyles.input,
        link: globalHeaderStyles.link
    } })); });
export var CollegeSearchWidget = widget('search')(function () { return collegeSearchStore; }, function (store, tracker) { return (React.createElement(CollegeSearchWidgetCore, { tracker: tracker, limit: store.config.limit, sensitivity: store.config.sensitivity, searchType: store.config.searchType, suggestions: store.suggestions, fetchSuggestions: store.fetchSuggestions, clearSuggestions: store.clear, startValue: "", onSelect: store.config.onSelect })); });
