exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cb-widget ._18z43H3ODTS48RyVuq_0xY{width:100%;overflow:hidden}.cb-widget ._18z43H3ODTS48RyVuq_0xY ._1C-dUy0bwEMLV_G0VkYFXs{display:flex;transition:transform .5s ease;transform:translateX(calc((-100%)))}.cb-widget ._18z43H3ODTS48RyVuq_0xY ._1C-dUy0bwEMLV_G0VkYFXs._5Js7ndDfXrfSUBK0E-S3X{transition:none;transform:translateX(0%)}.cb-widget ._18z43H3ODTS48RyVuq_0xY ._1C-dUy0bwEMLV_G0VkYFXs.unDvZFLR2XfT5cNp8offZ{transform:translateX(calc(2 * (-100%)))}.cb-widget ._18z43H3ODTS48RyVuq_0xY ._1C-dUy0bwEMLV_G0VkYFXs .wJXcqrU70TJU4Zglxlw2T{flex:1 0 100%}\n", ""]);

// exports
exports.locals = {
	"carousel-wrapper": "_18z43H3ODTS48RyVuq_0xY",
	"carousel-container": "_1C-dUy0bwEMLV_G0VkYFXs",
	"sliding": "_5Js7ndDfXrfSUBK0E-S3X",
	"prev": "unDvZFLR2XfT5cNp8offZ",
	"carousel-slot": "wJXcqrU70TJU4Zglxlw2T"
};