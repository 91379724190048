var escape = require("../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cb-widget ._1ySFo5TfResqpm-Jzt_ZR-{display:block;width:32px;height:24px;background:url(" + escape(require("../assets/bell_off.svg")) + ");background-size:32px 24px;background-repeat:no-repeat;background-position:top left}.cb-widget ._1ySFo5TfResqpm-Jzt_ZR- ._2AcroQdgSYYOnb1o2kyahy{display:none;width:32px;height:24px}.cb-widget ._1ySFo5TfResqpm-Jzt_ZR-._3DBwIvRq71jiuyoXhFCtFK{background:none}.cb-widget ._1ySFo5TfResqpm-Jzt_ZR-._3DBwIvRq71jiuyoXhFCtFK ._2AcroQdgSYYOnb1o2kyahy{display:block}\n", ""]);

// exports
exports.locals = {
	"college-list-bell-icon": "_1ySFo5TfResqpm-Jzt_ZR-",
	"college-list-bell-ring": "_2AcroQdgSYYOnb1o2kyahy",
	"ring": "_3DBwIvRq71jiuyoXhFCtFK"
};