var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import CollegeList from './CollegeList';
import './scss/collegeList.scss';
export { default as Bell } from './Bell';
export { default as Counter } from './Counter';
export { default as Carousel } from './Carousel';
export { default as Single } from './Popover/Single';
var CollegeListWidget = /** @class */ (function (_super) {
    __extends(CollegeListWidget, _super);
    function CollegeListWidget() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CollegeListWidget.prototype.render = function () {
        //number of selected colleges
        var _a = this.props, count = _a.count, active = _a.active, auth = _a.auth, update = _a.update, reOpt = _a.reOpt, opt = _a.opt;
        // const log = levity.isLoggedIn
        // const auth = true
        // const update = true
        // const reOpt = false
        // const opt = false
        return (React.createElement(CollegeList, { count: count, active: active, auth: auth, update: update, reOpt: reOpt, opt: opt }));
    };
    return CollegeListWidget;
}(Component));
export { CollegeListWidget };
